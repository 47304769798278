import { observable, action, makeObservable, runInAction } from 'mobx';
import {
  FuzzySeachBlog,
  SearchGeneralBlog,
  SearchBlogRecordsCount,
  SearchBlogByCategory,
  SearchBlogByCategoryRecordsCount,
  SearchBlogPublishHistory,
  BlogInfo,
} from '../service/blog';

class BlogStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  FuzzySeachBlogList = []; // 搜索框内模糊查询的展示结果

  @observable
  GeneralBlogList = []; // 首页整体展示的博客大略信息 每次保存一页的内容

  @observable
  GeneralBlogRecordsCount = 1; // 首页整体展示的博客的总数量 用于分页

  @observable
  CategoryBlogList = []; // 按照分类查询得到的博客列表

  @observable
  CategoryBlogRecordsCount = 1; // 按照分类查询得到的博客总数量

  @observable
  BlogPublishHistory = []; // 博客发表历史

  @observable
  BlogDetailInfo = {}; // 博客具体信息

  @action
  async FuzzySeachBlog(title) {
    var data = await FuzzySeachBlog(title);
    runInAction(() => {
      this.FuzzySeachBlogList = data.blogs;
    });
  }

  @action
  async GeneralSearchBlog(pagesize, pagenum) {
    var data = await SearchGeneralBlog(pagesize, pagenum);

    runInAction(() => {
      this.GeneralBlogList = data.blogs;
    });
  }

  @action
  async SearchBlogRecordsCount() {
    var data = await SearchBlogRecordsCount();

    runInAction(() => {
      this.GeneralBlogRecordsCount = data.records;
    });
  }

  @action
  async SearchBlogByCategory(category_id, page_num, page_size) {
    var data = await SearchBlogByCategory(category_id, page_num, page_size);
    runInAction(() => {
      this.CategoryBlogList = data.blogs;
    });
  }

  @action
  async SearchBlogByCategoryRecordsCount(category_id) {
    var data = await SearchBlogByCategoryRecordsCount(category_id);
    runInAction(() => {
      this.CategoryBlogRecordsCount = data.records;
    });
  }

  @action
  async SearchBlogPublishHistory() {
    var data = await SearchBlogPublishHistory();
    runInAction(() => {
      this.BlogPublishHistory = data.blogs;
    });
  }

  @action
  async BlogInfo(blogid) {
    var data = await BlogInfo(blogid);
    runInAction(() => {
      this.BlogDetailInfo.blog_content = data.blog_content;
      this.BlogDetailInfo.blog_id = data.blog_id;
      this.BlogDetailInfo.blog_title = data.blog_title;
      this.BlogDetailInfo.edit_time = data.edit_time;
      this.BlogDetailInfo.visits = data.visits;
    });
  }
}

export default BlogStore;
