import React from 'react';
import Menu from './items/topmenu';
import { Timeline } from 'antd';
import './css/history.css';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
@inject('blogStore')
@observer
class VisitorHistoryBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
    };

    this.blogStore = this.props.blogStore;

    this.resizeofindex = this.resizeofindex.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    await this.blogStore.SearchBlogPublishHistory();
    window.addEventListener('resize', this.resizeofindex); //增加
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }

  render() {
    return (
      <div>
        <Menu clientWidth={this.state.clientWidth}></Menu>
        <div className='contentforhistory'>
          <div className='articleinfocardforhistory'>
            <Timeline>
              {this.blogStore.BlogPublishHistory.map((item, index) => {
                return (
                  <div className='animationofhistory'>
                    <Timeline.Item>
                      <div>
                        <Link to={'/blog/' + item.blog_id}>
                          <font>{item.title}</font>
                        </Link>
                      </div>
                      <font>
                        {moment(new Date(item.publish_time)).format('YYYY-MM-DD HH:mm:ss')}
                      </font>
                    </Timeline.Item>
                  </div>
                );
              })}
            </Timeline>
          </div>
        </div>
      </div>
    );
  }
}

export default VisitorHistoryBlog;
