import React, { createRef } from 'react';
import { Drawer, Divider, Dropdown, Tag, Space, Input } from 'antd';
import { Affix } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import {
  HomeOutlined,
  PlaySquareOutlined,
  RocketOutlined,
  SmileOutlined,
  MenuUnfoldOutlined,
  RightCircleOutlined,
  CaretDownOutlined,
  GithubOutlined,
  TagOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

// 加载资源
import './items.css';

@inject('userInfoStore')
@inject('categoryinfoStore')
@inject('blogStore')
@observer
class BlogMenu extends React.Component {
  static defaultProps = {
    clientWidth: 701,
  };

  constructor(props) {
    super(props);

    this.state = {
      Drawervisible: false,
      fuzzysearchdisplay: 'none',
      fuzzysearchvalue: '',
    };

    // store 信息存储位置
    this.userinfo = this.props.userInfoStore;
    this.categoryinfo = this.props.categoryinfoStore;
    this.blogStore = this.props.blogStore;

    // 方法
    this.showDrawer = this.showDrawer.bind(this);
    this.DrawerClose = this.DrawerClose.bind(this);
    this.FuzzySearch = this.FuzzySearch.bind(this);
    this.FuzzySearchBlur = this.FuzzySearchBlur.bind(this);
    this.fuzzySearchOnclick = this.fuzzySearchOnclick.bind(this);
    this.FuzzySearchOnMouseOver = this.FuzzySearchOnMouseOver.bind(this);
    // dom元素识别
    this.fuzzysearchinpuRef = createRef();
    this.fuzzysearchRef = createRef();
  }

  componentDidMount() {
    this.userinfo.GetOwnerInfo();
    this.categoryinfo.GetCategoryInfo();
  }

  // 展示右边栏
  showDrawer() {
    this.setState({ Drawervisible: true });
  }
  // 关闭右边栏
  DrawerClose() {
    this.setState({ Drawervisible: false });
  }

  // 搜索框模糊查询
  async FuzzySearch() {
    this.setState({
      fuzzysearchvalue: this.fuzzysearchinpuRef.current.input.value,
    });
    await this.blogStore.FuzzySeachBlog(this.fuzzysearchinpuRef.current.input.value);
    if (this.blogStore.FuzzySeachBlogList.length > 0) {
      this.setState({ fuzzysearchdisplay: 'block' });
    } else {
      this.setState({ fuzzysearchdisplay: 'none' });
    }
  }

  // FuzzySearchBlur 焦点移除时取消显示查询结果
  async FuzzySearchBlur() {
    this.setState({ fuzzysearchdisplay: 'none' });
    // this.setState({
    //   fuzzysearchvalue: '',
    // });
    // await this.blogStore.FuzzySeachBlog('');
    // if (this.blogStore.FuzzySeachBlogList.length > 0) {
    //   this.setState({ fuzzysearchdisplay: 'block' });
    // } else {
    //   this.setState({ fuzzysearchdisplay: 'none' });
    // }
  }

  async fuzzySearchOnclick(blogid) {
    this.props.history.push('/blog/' + blogid);
  }

  FuzzySearchOnMouseOver(e) {
    e.target.style.background = 'black';
  }

  render() {
    return (
      <>
        {this.props.clientWidth > global.PageChangeWidth ? (
          <div>
            <div className='menu'>
              <div className='bloglogo'>
                <Space>
                  <Link to={'/'}>
                    <RocketOutlined />
                    {this.userinfo.NickName}
                  </Link>
                  <a href={this.userinfo.Github_Address} color='green' target='_blank'>
                    <font className='fontlink'>
                      <GithubOutlined />
                    </font>
                  </a>
                </Space>
              </div>

              <div className='menubox'>
                <Link to={'/'}>
                  <font className='fontlink'>
                    <HomeOutlined />
                    首页
                  </font>
                </Link>
              </div>

              <div className='menubox'>
                <Link to={'/history'}>
                  <font className='fontlink'>
                    <PlaySquareOutlined />
                    历史
                  </font>
                </Link>
              </div>

              <div className='menubox'>
                <Link to={'/micro-blog'}>
                  <font className='fontlink'>
                    <SmileOutlined />
                    动态
                  </font>
                </Link>
              </div>
              <div className='menubox'>
                <Link to={'/album'}>
                  <font className='fontlink'>
                    <PictureOutlined />
                    相册
                  </font>
                </Link>
              </div>

              <div className='menubox'>
                <Dropdown
                  overlay={
                    <div className='classposition'>
                      {this.categoryinfo.CategoryList.map((item, index) => {
                        return (
                          <Link to={'/category/' + item.category_id}>
                            <Tag color='#00c6f1'>
                              <font className='specificclass'>{item.category_name}</font>
                            </Tag>
                          </Link>
                        );
                      })}
                    </div>
                  }
                  trigger={['click']}>
                  <font onClick={e => e.preventDefault()} className='atextforclass'>
                    <CaretDownOutlined />
                    分类
                  </font>
                </Dropdown>
              </div>

              <div className='searchbox'>
                <Input
                  size='large'
                  placeholder='搜索文章'
                  onChange={this.FuzzySearch}
                  onClick={this.FuzzySearch}
                  ref={this.fuzzysearchinpuRef}
                  value={this.state.fuzzysearchvalue}
                  onBlur={this.FuzzySearchBlur}
                />
                <div
                  style={{ display: this.state.fuzzysearchdisplay }}
                  className='fuzzysearchresult'>
                  {this.blogStore.FuzzySeachBlogList.map((item, index) => {
                    return (
                      // 想加一个选中后的特效
                      <div className='fuzzysearchresultunit'>
                        <font
                          key={item.blog_id}
                          onMouseDown={() => {
                            this.fuzzySearchOnclick(item.blog_id);
                          }}>
                          {item.blog_title}
                        </font>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='menu'>
              <Link to={'/'}>
                <div className='bloglogo'>
                  <RocketOutlined />
                  {this.userinfo.NickName}
                </div>
              </Link>

              <div className='menuunfoldout'>
                <MenuUnfoldOutlined onClick={this.showDrawer} />
              </div>
              <Drawer
                title={this.userinfo.Signature}
                placement='right'
                headerStyle={{
                  backgroundColor: '#feeeed',
                  textAlign: 'center',
                  fontFamily: '华文楷体',
                }}
                drawerStyle={{ backgroundColor: '#feeeed' }}
                width={300}
                closeIcon={<RightCircleOutlined />}
                onClose={this.DrawerClose}
                visible={this.state.Drawervisible}>
                <p className='menutitle'>
                  <Link to={'/history'}>
                    <font className='drawerfont'>
                      <PlaySquareOutlined />
                      历史
                    </font>
                  </Link>
                </p>
                <Divider></Divider>
                <p className='menutitle'>
                  <Link to={'/micro-blog'} color='black'>
                    <font className='drawerfont'>
                      <SmileOutlined />
                      动态
                    </font>
                  </Link>
                </p>
                <Divider></Divider>
                <p className='menutitle'>
                  <Link to={'/album'}>
                    <font className='drawerfont'>
                      <PictureOutlined />
                      相册
                    </font>
                  </Link>
                </p>
                <Divider></Divider>
                <p className='menutitle'>
                  <TagOutlined />
                  分类
                </p>
                {this.categoryinfo.CategoryList.map((item, index) => {
                  return (
                    <Link to={'/category/' + item.category_id} key={index}>
                      <Tag color='#00c6f1'>
                        <a className='specificclass'>{item.category_name}</a>
                      </Tag>
                    </Link>
                  );
                })}
                <Divider></Divider>
                <a href={this.userinfo.Github_Address} color='green' target='_blank'>
                  <GithubOutlined />
                </a>
              </Drawer>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(BlogMenu);
