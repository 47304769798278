import React from 'react';
import Menu from './items/topmenu';
import { inject, observer } from 'mobx-react';
import './css/album.css';
import moment from 'moment';
import { Pagination, Image } from 'antd';
import { Link, withRouter } from 'react-router-dom';

@inject('albumStore')
@observer
class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
      albumid: parseInt(this.props.match.params.albumid),
    };

    this.albumStore = this.props.albumStore;
    this.resizeofindex = this.resizeofindex.bind(this);
    this.ImagesPageChangePage = this.ImagesPageChangePage.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
    await this.albumStore.GetImagesFromAlbum(this.state.albumid, 20, 1);
    await this.albumStore.GetAlbumImageNum(this.state.albumid);
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }

  // ImagesPageChangePage 页面切换时改变显示数据
  async ImagesPageChangePage(pagenum, pagesize) {
    await this.albumStore.GetImagesFromAlbum(this.state.albumid, pagesize, pagenum);
    await this.albumStore.GetAlbumImageNum(this.state.albumid);
  }

  render() {
    return (
      <div>
        <Menu clientWidth={this.state.clientWidth}></Menu>
        <div className='content'>
          {this.state.clientWidth > global.PageChangeWidth ? (
            <div className='albumplace'>
              {this.albumStore.imagelists.map((item, index) => {
                return (
                  <div className='albumitem'>
                    <div>
                      <Image width={200} src={item.url} />
                      <div>
                        <font>{item.image_intro}</font>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='albumplace'>
              {this.albumStore.imagelists.map((item, index) => {
                return (
                  <div className='albumitemobile' key={index}>
                    <div>
                      <Image src={item.url} />
                      <div>
                        <font>{item.image_intro}</font>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className='pagenationofindex'>
            <Pagination
              pageSize={20}
              size={'small'}
              onChange={this.ImagesPageChangePage}
              total={this.albumStore.imagenum}
              hideOnSinglePage={true}
              showSizeChanger={false}
              simple={true}
              style={{ textAlign: 'center !important' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Picture);
