import { observable, action, makeObservable, runInAction } from 'mobx';
import { GetAlbum, GetImagesFromAlbum, GetAlbumImageNum } from '../service/album';

class AlbumStore {
  constructor() {
    makeObservable(this);
  }

  // 图片列表
  @observable
  imagelists = [];

  // 相册列表
  @observable
  albumlists = [];

  // 图片数量
  @observable
  imagenum = 0;

  // 获取相册
  @action
  async GetAlbum() {
    var response = await GetAlbum();
    console.log(response.data);
    var albumlist = [];
    for (var i = 0; i < response.data.length; i++) {
      var imagedata = await GetImagesFromAlbum(response.data[i].album_id, 1, 1);
      var imagelistlimit1 = imagedata.data;
      var imageurl = '';
      if (imagelistlimit1.length == 0) {
        imageurl = '';
      } else {
        imageurl = imagelistlimit1[0].url;
      }
      var item = {
        album: response.data[i],
        index: imageurl,
      };
      albumlist.push(item);
    }
    runInAction(() => {
      this.albumlists = albumlist;
    });
  }

  // 获取图片
  @action
  async GetImagesFromAlbum(albumid, pagesize, pagenum) {
    var imagedata = await GetImagesFromAlbum(albumid, pagesize, pagenum);
    var imagelists = imagedata.data;
    runInAction(() => {
      this.imagelists = imagelists;
    });
  }

  // 获取相册图片数量
  @action
  async GetAlbumImageNum(albumid) {
    var result = await GetAlbumImageNum(albumid);

    runInAction(() => {
      this.imagenum = result.data;
    });
  }
}

export default AlbumStore;
