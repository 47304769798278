import axios from 'axios';
import { errNotification } from './utils';

// FuzzySeachBlog 按搜索内容模糊查询
export async function FuzzySeachBlog(title) {
  var response = await axios({
    method: 'GET',
    url: 'v1/blog/blog/fuzzy?blog_title=' + title,
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

// SearchBlogByCategory 按分类筛选博客
export async function SearchBlogByCategory(categoryid, pagenum, pagesize) {
  var response = await axios({
    method: 'POST',
    url: 'v1/blog/blog/category',
    data: {
      category_id: parseInt(categoryid),
      page_num: pagenum,
      page_size: pagesize,
    },
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

// SearchBlogByCategoryRecordsCount 该分类下的博客数量
export async function SearchBlogByCategoryRecordsCount(categoryid) {
  var response = await axios({
    method: 'POST',
    url: 'v1/blog/blog/categoryrecords',
    data: {
      category_id: parseInt(categoryid),
    },
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

// SearchBlog 首页展示博客
export async function SearchGeneralBlog(pagesize, pagenum) {
  var response = await axios({
    method: 'POST',
    data: {
      page_num: parseInt(pagenum),
      page_size: parseInt(pagesize),
    },
    url: 'v1/blog/blog/general',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

//  SearchBlogRecordsCount 查询博客记录数
export async function SearchBlogRecordsCount() {
  var response = await axios({
    method: 'GET',
    url: 'v1/blog/blog/generalrecords',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

// SearchBlogPublishHistory 搜索博客发表历史
export async function SearchBlogPublishHistory() {
  var response = await axios({
    url: 'v1/blog/blog/history',
    method: 'GET',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}

// BlogInfo 查询文章具体信息
export async function BlogInfo(blogid) {
  var response = await axios({
    url: 'v1/blog/blog/detail',
    method: 'POST',
    data: {
      blog_id: parseInt(blogid),
    },
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}
