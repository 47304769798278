import { SearchTinyblogPublishHistory } from '../service/tinyblog';
import { observable, action, makeObservable, runInAction } from 'mobx';
class TinyBlogStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  TinyBlogPublishHistory = []; // 动态情况

  @action
  async SearchTinyblogPublishHistory() {
    var data = await SearchTinyblogPublishHistory();
    runInAction(() => {
      this.TinyBlogPublishHistory = data.tinyblogs;
    });
  }
}

export default TinyBlogStore;
