import UserinfoStore from './user';
import CategoryinfoStore from './category';
import BlogStore from './blog';
import TinyBlogStore from './tinyblog';
import AlbumStore from './album';
const userInfoStore = new UserinfoStore();
const categoryinfoStore = new CategoryinfoStore();
const blogStore = new BlogStore();
const tinyblogStore = new TinyBlogStore();
const albumStore = new AlbumStore();

const stores = {
  userInfoStore,
  categoryinfoStore,
  blogStore,
  tinyblogStore,
  albumStore,
};

export { stores };
