import { observable, action, makeObservable, runInAction } from 'mobx';
import { GetCategory } from '../service/category';

class CategoryinfoStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  CategoryList = [];

  @action
  async GetCategoryInfo() {
    var data = await GetCategory();
    runInAction(() => {
      this.CategoryList = data.categories;
    });
  }
}

export default CategoryinfoStore;
