import React from 'react';
import Menu from './items/topmenu';
import { inject, observer } from 'mobx-react';
import './css/album.css';
import moment from 'moment';
import { Space, Image } from 'antd';
import { Link } from 'react-router-dom';

@inject('albumStore')
@observer
class Album extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
    };

    this.albumStore = this.props.albumStore;
    this.resizeofindex = this.resizeofindex.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
    await this.albumStore.GetAlbum();
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }
  render() {
    return (
      <div>
        <Menu clientWidth={this.state.clientWidth}></Menu>
        <div className='content'>
          {this.state.clientWidth > global.PageChangeWidth ? (
            <div className='albumplace'>
              {this.albumStore.albumlists.map((item, index) => {
                return (
                  <div className='albumitem'>
                    <Link to={'/images/' + item.album.album_id}>
                      <div>
                        <Image
                          width={200}
                          src={item.index}
                          preview={false}
                          style={{ filter: 'blur(3px)' }}
                        />
                        <div>
                          <font className='albumname'>{item.album.album_name}</font>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='albumplace'>
              {this.albumStore.albumlists.map((item, index) => {
                return (
                  <div className='albumitemobile'>
                    <Link to={'/images/' + item.album.album_id}>
                      <div>
                        <Image src={item.index} preview={false} style={{ filter: 'blur(3px)' }} />
                        <div>
                          <font className='albumname'>{item.album.album_name}</font>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Album;
