import React from 'react';
import Menu from './items/topmenu';
import { inject, observer } from 'mobx-react';
import './css/blog.css';
import moment from 'moment';
import { Space } from 'antd';
@inject('blogStore')
@observer
class VisitorBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
    };

    this.blogStore = this.props.blogStore;
    this.resizeofindex = this.resizeofindex.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
    await this.blogStore.BlogInfo(this.props.match.params.blogid);
    document.getElementById('content').innerHTML = this.blogStore.BlogDetailInfo.blog_content;
  }

  async componentWillReceiveProps(newProps) {
    await this.blogStore.BlogInfo(newProps.match.params.blogid);
    document.getElementById('content').innerHTML = this.blogStore.BlogDetailInfo.blog_content;
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }
  render() {
    return (
      <div>
        <Menu clientWidth={this.state.clientWidth}></Menu>
        <div className='blogbgcontent'>
          <div className='blogposition'>
            <div className='blogtitle'>
              <h1>{this.blogStore.BlogDetailInfo.blog_title}</h1>
              <Space>
                <font>
                  最近修改时间:
                  {moment(new Date(this.blogStore.BlogDetailInfo.edit_time)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </font>
                <font>浏览量:{this.blogStore.BlogDetailInfo.visits}</font>
              </Space>
            </div>
            <div className='blogcontent' id='content'></div>
          </div>
        </div>
      </div>
    );
  }
}

export default VisitorBlog;
