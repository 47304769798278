import axios from 'axios';
import { errNotification } from './utils';

// SearchTinyblogPublishHistory 搜索动态发表历史
export async function SearchTinyblogPublishHistory() {
  var response = await axios({
    url: 'v1/blog/tinyblog/maintopn',
    method: 'GET',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });

  var data = response.data;
  return data;
}
