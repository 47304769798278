import { observable, action, makeObservable, runInAction } from 'mobx';
import { GetUserInfo } from '../service/user';

class UserinfoStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  Github_Address = '';

  @observable
  NickName = '';

  @observable
  Signature = '';

  @observable
  SelfIntro = '';

  @observable
  Portrait = '';

  @action
  async GetOwnerInfo() {
    var data = await GetUserInfo();
    runInAction(() => {
      this.Github_Address = data.github_address;
      this.NickName = data.nickname;
      this.Signature = data.signature;
      this.SelfIntro = data.self_introduction;
      this.Portrait = data.portrait;
    });
  }
}

export default UserinfoStore;
