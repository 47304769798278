import axios from 'axios';
import { errNotification } from './utils';

export async function GetUserInfo() {
  var response = await axios({
    method: 'GET',
    url: 'v1/blog/userinfo/main',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });
  var data = response.data;
  return data;
}
