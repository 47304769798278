import './App.css';

// 全局路由配置文件
import './global/index';
import Vistorindex from './visitor/index';
import VisitorMicroBlog from './visitor/micro-blog';
import VisitorHistoryBlog from './visitor/history';
import VisitorCategory from './visitor/category';
import Picture from './visitor/picture';
import Album from './visitor/album';
import { HashRouter, Route, Switch } from 'react-router-dom';
import VisitorBlog from './visitor/blog';
import React from 'react';
import 'antd/dist/antd.min.css';
import { stores } from './store';
import { Provider } from 'mobx-react';
import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:4001/';

class App extends React.Component {
  render() {
    return (
      <div>
        <Provider {...stores}>
          <HashRouter basename='/'>
            <Switch>
              <Route exact path='/' component={Vistorindex}></Route>
              <Route exact path='/blog/:blogid' component={VisitorBlog}></Route>
              <Route exact path='/micro-blog' component={VisitorMicroBlog}></Route>
              <Route exact path='/history' component={VisitorHistoryBlog}></Route>
              <Route exact path='/category/:categoryid' component={VisitorCategory}></Route>
              <Route exact path='/album' component={Album}></Route>
              <Route exact path='/images/:albumid' component={Picture}></Route>
            </Switch>
          </HashRouter>
        </Provider>
      </div>
    );
  }
}

export default App;
