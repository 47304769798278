import axios from 'axios';
import { errNotification } from './utils';

// GetAlbum 获取相册信息
export async function GetAlbum() {
  var response = await axios({
    method: 'GET',
    url: '/v1/blog/album/albumvisitor',
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });

  var data = response.data;
  return data;
}

// GetAlbumImageNum 获取相册内图片数量
export async function GetAlbumImageNum(albumid) {
  var response = await axios({
    method: 'POST',
    url: '/v1/blog/album/albumimagesnumvisitor',
    data: {
      albumid: parseInt(albumid),
    },
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });

  var data = response.data;
  return data;
}

// GetImagesFromAlbum 从相册中获取图片
export async function GetImagesFromAlbum(albumid, pagesize, pagenum) {
  var response = await axios({
    method: 'POST',
    url: '/v1/blog/album/albumimagesvisitor',
    data: {
      albumid: parseInt(albumid),
      pagesize: parseInt(pagesize),
      pagenum: parseInt(pagenum),
    },
  }).catch(function (error) {
    if (error.response) {
      var data = error.response.data;
      errNotification('error', data.error_code, data.message);
    } else {
      errNotification('error', 'unknow', error);
    }
  });

  var data = response.data;
  return data;
}
