import React from 'react';
import BlogMenu from './items/topmenu';
import ArticleinfoCard from './items/articleinfocard';

import './css/index.css';

class VisitorIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
    };
    this.resizeofindex = this.resizeofindex.bind(this);
  }

  // 组件加载完成 增加监听
  componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }
  render() {
    return (
      <div>
        <BlogMenu clientWidth={this.state.clientWidth}></BlogMenu>

        <div className='content'>
          {/* <UserinfoCard clientWidth={this.state.clientWidth}></UserinfoCard> */}
          <div className='articlepo'>
            <ArticleinfoCard clientWidth={this.state.clientWidth}></ArticleinfoCard>
          </div>
          <div className='foot'>
            <font className='afont'>
              <a style={{ color: 'aliceblue' }} href='https://beian.miit.gov.cn'>
                浙ICP备20007305号
              </a>
            </font>
          </div>
        </div>
      </div>
    );
  }
}

export default VisitorIndex;
