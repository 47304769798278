import React from 'react';
import Menu from './items/topmenu';
import { Link, withRouter } from 'react-router-dom';
import { Pagination } from 'antd';
import './css/category.css';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

@inject('categoryinfoStore')
@inject('blogStore')
@observer
class VisitorCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
      categoryName: '',
      categoryId: parseInt(this.props.match.params.categoryid),
    };

    // 存储
    this.categoryinfoStore = this.props.categoryinfoStore;
    this.blogStore = this.props.blogStore;

    // 函数绑定
    this.resizeofindex = this.resizeofindex.bind(this);
    this.CategoryBlogChangePage = this.CategoryBlogChangePage.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
    await this.categoryinfoStore.GetCategoryInfo();
    for (var i = 0; i < this.categoryinfoStore.CategoryList.length; i++) {
      if (this.categoryinfoStore.CategoryList[i].category_id === this.state.categoryId) {
        this.setState({
          categoryName: this.categoryinfoStore.CategoryList[i].category_name,
        });
      }
    }
    await this.blogStore.SearchBlogByCategory(this.state.categoryId, 1, 20);
    await this.blogStore.SearchBlogByCategoryRecordsCount(this.state.categoryId);
  }

  async componentWillReceiveProps(newProps) {
    this.setState({
      categoryId: parseInt(newProps.match.params.categoryid),
    });
    await this.categoryinfoStore.GetCategoryInfo();
    for (var i = 0; i < this.categoryinfoStore.CategoryList.length; i++) {
      if (this.categoryinfoStore.CategoryList[i].category_id === this.state.categoryId) {
        this.setState({
          categoryName: this.categoryinfoStore.CategoryList[i].category_name,
        });
      }
    }

    await this.blogStore.SearchBlogByCategory(this.state.categoryId, 1, 20);
    await this.blogStore.SearchBlogByCategoryRecordsCount(this.state.categoryId);
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }

  async CategoryBlogChangePage(pagenum, pagesize) {
    await this.blogStore.SearchBlogByCategory(this.state.categoryId, pagenum, pagesize);
    await this.blogStore.SearchBlogByCategoryRecordsCount(this.state.categoryId);
  }
  render() {
    return (
      <div>
        <Menu clientWidth={this.state.clientWidth}></Menu>
        <div className='contentforcategory'>
          <div className='articleinfocardforcategory'>
            <div className='categoryname'>分类-{this.state.categoryName}</div>
            <div className='categorycount'>当前共{this.blogStore.CategoryBlogRecordsCount}文章</div>
            <ul>
              {this.blogStore.CategoryBlogList.map((item, index) => {
                return (
                  <li className='animationofarticlecategory'>
                    <Link to={'/blog/' + item.blog_id}>
                      <font className='titleforcategory'>{item.blog_title}</font>
                    </Link>
                    <font className='publishtimeforcategory'>
                      {moment(new Date(item.edit_time)).format('YYYY-MM-DD HH:mm:ss')}
                    </font>
                  </li>
                );
              })}
            </ul>
            <div className='pagenationofcategoryblog'>
              <Pagination
                pageSize={20}
                size={'small'}
                onChange={this.CategoryBlogChangePage}
                total={this.blogStore.CategoryBlogRecordsCount}
                hideOnSinglePage={true}
                showSizeChanger={false}
                simple={true}
                style={{ textAlign: 'center !important' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VisitorCategory);
