import React from 'react';
import BlogMenu from './items/topmenu';
import { Timeline } from 'antd';
import './css/microblog.css';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
@inject('tinyblogStore')
@observer
class VisitorMicroBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientWidth: document.documentElement.clientWidth,
    };

    this.tinyblogStore = this.props.tinyblogStore;

    this.resizeofindex = this.resizeofindex.bind(this);
  }

  // 组件加载完成 增加监听
  async componentDidMount() {
    window.addEventListener('resize', this.resizeofindex); //增加
    await this.tinyblogStore.SearchTinyblogPublishHistory();
  }

  // 移除监听
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeofindex); //取消
  }

  resizeofindex() {
    this.setState({ clientWidth: document.documentElement.clientWidth }); //监听
  }
  render() {
    return (
      <div>
        <BlogMenu clientWidth={this.state.clientWidth}></BlogMenu>
        <div className='contentformicroblog'>
          <div className='articleinfocardformicroblog'>
            <Timeline>
              {this.tinyblogStore.TinyBlogPublishHistory.map((item, index) => {
                return (
                  <div className='animationoftinyblog'>
                    <Timeline.Item>
                      <div className='dialog'>{item.TinyBlogContent} </div>
                      <br></br>
                      <font className='timetamp'>
                        {moment(new Date(item.PublishTime)).format('YYYY-MM-DD HH:mm:ss')}
                      </font>
                    </Timeline.Item>
                  </div>
                );
              })}
            </Timeline>
          </div>
        </div>
      </div>
    );
  }
}

export default VisitorMicroBlog;
