import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Pagination } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
// 加载资源
import './items.css';

@inject('blogStore')
@observer
class ArticleinfoCard extends React.Component {
  static defaultProps = {
    clientWidth: 701,
  };

  constructor(props) {
    super(props);

    // 加载存储
    this.blogStore = this.props.blogStore;

    // 方法
    this.GeneralBlogChangePage = this.GeneralBlogChangePage.bind(this);
  }

  componentDidMount() {
    this.blogStore.GeneralSearchBlog(5, 1);
    this.blogStore.SearchBlogRecordsCount();
  }

  // GeneralBlogChangePage 页面切换时改变显示数据
  async GeneralBlogChangePage(pagenum, pagesize) {
    await this.blogStore.GeneralSearchBlog(pagesize, pagenum);
    await this.blogStore.SearchBlogRecordsCount();
  }

  render() {
    return (
      <div>
        {this.blogStore.GeneralBlogList.map((item, index) => {
          return (
            <div className='articleinfocard'>
              <div className='singleblog'>
                <div className='articletitle'>
                  <Link to={'/blog/' + item.blog_id}>
                    <font color={'black'}>{item.blog_title}</font>
                  </Link>
                </div>
                <div className='someinfo'>
                  {moment(new Date(item.edit_time)).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div className='articlecontent'>{item.blog_general_content}</div>
                <div>
                  <div className='butreadarti'>
                    <font color={'black'}>
                      <Link to={'/blog/' + item.blog_id}>
                        <Button type='primary' shape='round' size={'middle'}>
                          查看全文
                        </Button>
                      </Link>
                    </font>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className='pagenationofindex'>
          <Pagination
            pageSize={5}
            size={'small'}
            onChange={this.GeneralBlogChangePage}
            total={this.blogStore.GeneralBlogRecordsCount}
            hideOnSinglePage={true}
            showSizeChanger={false}
            simple={true}
            style={{ textAlign: 'center !important' }}
          />
        </div>
      </div>
    );
  }
}

export default ArticleinfoCard;
